<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Faq's</h3>
       <v-select
          style="margin-right: 1em; margin-top: .5em;"
          :items="sistemas"
          label="Escolha um Sistema"
          outlined
          hide-details
          v-model="sistema"
          @change="requisitarPerguntas"
        ></v-select>
      </span>
      <div class="grow"/>
      <button type="button" class="btn"  v-if="loading" @click="$router.push('/criar/faq')">
        <v-icon color="#f9f9f2" size='28'>mdi-plus-circle-outline</v-icon>
      </button>
    </div>
    <section class="wrapper-list" v-if="loading" >
      <!-- lista de usuarios -->
      <v-data-table 
        :items="items"
        :headers="headers"
        :items-per-page="6"
        @click:row="(item) => $router.push(`/lista/faq/${item.id}`)">   

          <template v-slot:[`item.idsistema`]="{ item }">
            {{sistemasdb.filter(el => el.id === item.idsistema)[0].nome}}
          </template>        

          <template v-slot:[`item.idcategoria`]="{ item }">
            {{categoriasdb.filter(el => el.id === item.idcategoria)[0].nome}}
          </template>  
      </v-data-table>
    </section>
    <list-skeleton v-if="!loading"/>
  </v-container>
</template>

<script>
import FaqServico from '@/services/faq.service.js'
import SistemaServico from '@/services/sistema.service.js'
import ListSkeleton from '../Loading/ListSkeleton.vue'
export default {
  components: { ListSkeleton },
  data(){
    return{
      loading: false,
      headers: [
        { text: 'Id', align: 'start', value: 'id' },
        { text: 'Sistema', align: 'start', value: 'idsistema' },
        { text: 'Categoria', align: 'start', value: 'idcategoria' },
        { text: 'Pergunta', align: 'start', value: 'pergunta' },
      ],
      items: [],
      sistema: null,
      sistemas: [],
      sistemasdb: [],
      categoria: null,
      categorias: [],
      categoriasdb: []
    }
  },
  methods:{
    async requisitarSistemas(){
      const sistemas = await SistemaServico.buscar().then(res=> res.data)
      this.sistemas = sistemas.map(res => res.nome)
      this.sistemasdb = sistemas
    },
    async requisitarCategorias(){
      const categorias = await FaqServico.buscarCategorias().then(res=> res.data)
      console.log(categorias)
      this.categorias = categorias.map(res => res.nome)
      this.categoriasdb = categorias

    },
    async requisitarPerguntas(){
      const idsistema = this.sistemasdb.filter(el => el.nome === this.sistema)[0].id
      localStorage.setItem('idsistema', idsistema)
      this.items = await FaqServico.buscarPerguntas({idsistema, somentepergunta: true}).then(res => res.data)
    }
  },
  async mounted(){
    await this.requisitarSistemas()
    await this.requisitarCategorias()
    let idsistemalocal = localStorage.getItem('idsistema')
    if(idsistemalocal){
      console.log(this.sistemasdb)
      this.sistema = this.sistemasdb.filter(el => el.id == idsistemalocal)[0].nome
      console.log(this.sistema)
      await this.requisitarPerguntas()
    }
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;  

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: row;
    align-items: center;
    >h3{
        margin-right: .5em;
    }
  }
  @media screen and (max-width: 768px) {
      width: 100% !important;      
      >span{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
      margin: auto 0;
  }

.grow{
  flex: 1;
}

</style>