<template>
    <ListaFaq />
</template>

<script>
import ListaFaq from '@/components/Faq/ListaFaq'
export default {
  components: {ListaFaq},
  data(){
    return{}
  }
}
</script>

<style>

</style>